import _export from "../internals/export";
import _flattenIntoArray from "../internals/flatten-into-array";
import _aCallable from "../internals/a-callable";
import _toObject from "../internals/to-object";
import _lengthOfArrayLike from "../internals/length-of-array-like";
import _arraySpeciesCreate from "../internals/array-species-create";
var $ = _export;
var flattenIntoArray = _flattenIntoArray;
var aCallable = _aCallable;
var toObject = _toObject;
var lengthOfArrayLike = _lengthOfArrayLike;
var arraySpeciesCreate = _arraySpeciesCreate;

// `Array.prototype.flatMap` method
// https://tc39.es/ecma262/#sec-array.prototype.flatmap
$({
  target: "Array",
  proto: true
}, {
  flatMap: function flatMap(callbackfn /* , thisArg */) {
    var O = toObject(this);
    var sourceLen = lengthOfArrayLike(O);
    var A;
    aCallable(callbackfn);
    A = arraySpeciesCreate(O, 0);
    A.length = flattenIntoArray(A, O, O, sourceLen, 0, 1, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
    return A;
  }
});
export default {};